<template>
  <div
    class="faq-box"
    :class="item.ask.length > 1 ? 'hover-full-height' : ''"
  >
    <div class="wrapper">
      <div class="header">
        <div class="icon-box">
          <v-icon>mdi-google-analytics </v-icon>
        </div>
        <div class="head-title">
          {{ item?.title || "title" }}
        </div>
      </div>
      <div class="faq">
        <span
          v-for="(r, i) in item.ask"
          :key="i"
          class="link"
          @click="goTo(r)"
        >
          <span class="num">{{ i + 1 }}. </span>
          <span> &nbsp; {{ r }}</span>
        </span>
      </div>
    </div>
    <!--  -->
    <div class="ask-about">
      <v-btn @click="goToByButton">
        <v-icon> mdi-flag</v-icon>
        <span>Ask about {{ item?.title }} </span>
        <v-icon> mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: "ORKOChatFAQ",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      seeMore: true,
    };
  },
  methods: {
    goTo(v) {
      const params = {
        title: this.item.title,
        slug: this.item.slug,
        data: v,
      };

      this.$emit("click", params);
    },
    goToByButton() {
      const params = {
        title: this.item.title,
        slug: this.item.slug,
        data: null,
      };

      this.$emit("click", params);
    },
  },
};
</script>
<style lang="sass" scoped>
.faq-box
    position: relative
    display: flex
    justify-content: space-between
    flex-direction: column
    background: #fff
    box-shadow: 2px 2px 11px 2px #d2d2d2
    border-radius: 5px
    padding: 1.5rem 1.5rem
    flex-basis: 30%
    transition: all 0.5s ease
    &:hover
      z-index: 1
      transform: scale(1.05)
      border: 1px solid #4f46e5
    .header
      display: flex
      align-items: center
      gap: 0.5rem
      .head-title
        // color: #1CADA1
        font-size : 16px
        font-weight: 600


    .faq
      margin-top: 1rem
      > span
        display: flex
        transition: all 0.5s ease
        .num
          color: #4338CA
        span
          margin-bottom: 0.5rem



.link
  cursor: pointer
  &:hover
    // color: #1CADA1
    padding-left: 1rem

.ask-about
  display: flex
  justify-content: center
  :deep .v-btn
    color: #4338CA !important
    background-color: #eef2ff !important
    display: flex
    justify-content: space-around
    .v-btn__content
      gap: 1rem
</style>
